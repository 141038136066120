import { useState, useEffect } from "react";
import "../CSS/PItemPortfolio.css";
import "../CSS/pSkillItemAnimations.css";
import "../CSS/PItemSkill.css";

export default function PItem({ aLink, pTitle, pImg, pos, index, zPos, type, anim, children }) {
    const [currPos, setCurrPos] = useState(25);
    const leftFade = -58.25;
    const leftEdge = -10;
    const center = 38.25;
    const rightEdge = 86.5;
    const rightFade = 134.75;
    useEffect(() => {
        if (index === (pos - 1)) setCurrPos(leftEdge);
        else if (index < pos) setCurrPos(leftFade);
        else if (index === (pos + 1)) setCurrPos(rightEdge);
        else if (index > pos) setCurrPos(rightFade);
        else setCurrPos(center);
    }, [index, pos]);
    // If the item's position is in one of the fade zones, its opacity will be 0.
    const opacity = ((currPos === rightFade || currPos === leftFade) ? 0 : 100);
    const posStyle = {
        left: `${currPos}%`,
        filter: `opacity(${opacity}%)`,
        zIndex: zPos
    }
    const [clicked, setClicked] = useState(false);
    const handleClick = () => { setClicked(!clicked); };
    
    return (
        <div className='pItemHitbox' style={posStyle}>
            <div className="pItemWrap">
                <div className={`pItem ${clicked ? 'clicked' : ''}`} onClick={handleClick}>
                    <div className={"pImg " + pImg}>
                        <h5>{pTitle}</h5>
                    </div>
                    <div className="pContent flex">
                        <p className="pl1 pr1">{children}</p>
                        {aLink === "#" ? <></> : <a className="flb31 tac" href={aLink} target="_blank" rel="noreferrer">View</a>}
                    </div>
                </div>
            </div>
        </div>
    );
}