import { useState, useEffect } from "react";
import "../CSS/PItemPortfolio.css";
import "../CSS/pSkillItemAnimations.css";
import "../CSS/PItemSkill.css";

export default function SkillItem({ pTitle, pos, index, zPos, anim, children }) {
    const [currPos, setCurrPos] = useState(25);
    const leftFade = -85;
    const leftEdge = -30;
    const center = 25;
    const rightEdge = 80;
    const rightFade = 135;
    useEffect(() => {
        if (index === (pos - 1)) setCurrPos(leftEdge);
        else if (index < pos) setCurrPos(leftFade);
        else if (index === (pos + 1)) setCurrPos(rightEdge);
        else if (index > pos) setCurrPos(rightFade);
        else setCurrPos(center);
    }, [index, pos]);
    // If the item's position is in one of the fade zones, its opacity will be 0.
    const opacity = ((currPos === rightFade || currPos === leftFade) ? 0 : 100);
    const posStyle = {
        left: `${currPos}%`,
        filter: `opacity(${opacity}%)`,
        zIndex: zPos
    }
    const [hoverInterval, setHoverInterval] = useState(null);
    function handleMouseEnter() {
        setHoverInterval(setInterval(() => {
            document.querySelectorAll('[class^="music"]').forEach((bar, i) => {
                bar.style.height = (Math.random() / (2 ** ((i - 2)/1.5) ** 2)) * 50 + 10 + "%";
            });
        }, 250));
    }
    function handleMouseLeave() {
        clearInterval(hoverInterval);
        setHoverInterval(null);
        document.querySelectorAll('[class^="music"]').forEach((bar) => {
            bar.style.height = "0%";
        });
    }
    return (
        <div className='pSkillItemHitbox' style={posStyle} onMouseEnter={anim === "music" ? handleMouseEnter : null} onMouseLeave={anim === "music" ? handleMouseLeave : null}>
            <div className="pSkillItemWrap">
                <div className={anim + "A"}>{anim === "lightbulb" ? <div></div> : <></>}</div>
                <div className={anim + "B"}>{anim === "lightbulb" ? <div></div> : <></>}</div>
                <div className={anim + "C"}>{anim === "lightbulb" ? <div></div> : <></>}</div>
                <div className={anim + "D"}></div>
                <div className={anim + "E"}></div>
                <div className="pSkillItem">
                    <h5>{pTitle}</h5>
                    <p className="pl1 pr1">{children}</p>
                </div>
            </div>
        </div>
    );
}