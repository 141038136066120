import "../CSS/hero.css";

export default function Hero({handleCTA}) {
    return (
        <div id="hero">
            <div className="heroSpacer"></div>
            <div className="heroImgBkg"><div className="pfp"></div></div>
            <div className="heroContainer">
                <div className="typeBoxContainer">
                    <div className='typeBox'><span className="caret">&gt;</span><span className="typeText">Cameron McCarty</span></div>
                    <div className='typeBox'><span className="caret">&gt;</span><span className="typeText">Web Developer</span></div>
                </div>
                <div className="ctaBtnWrap">
                    <div className="ctaBtn" onClick={handleCTA}></div>
                </div>
            </div>
        </div>
    );
}